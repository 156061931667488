/* General */
body {
  margin: 0;
}

.eq-tabs-tab,
.eq-menu-item {
  user-select: none;
}

#hubspot-messages-iframe-container.widget-align-left {
  transition: margin 0.2s;
  z-index: 1005;
}

.display-none {
  display: none;
}

.classname-position-relative {
  position: relative;
}

/* Button */
@keyframes notificationEffect {
  25%,
  75% {
    box-shadow: 0 0 0 0 #ff4d4f;
  }
  50% {
    box-shadow: 0 0 0 50px #ff4d4f;
  }
}

.button-notification-animation::before {
  content: '';
  display: block;
  box-shadow: 0 0 0 0 #ff4d4f;
  opacity: 0.1;
  animation: notificationEffect 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}

/* Checkbox */
.eq-checkbox-wrapper>span:empty {
  display: none;
}

/* Datasheet Grid */
.eq-form-item-has-error .dsg-cell .eq-select:not(.eq-select-disabled):not(.eq-select-customize-input) .eq-select-selector {
  border-color: transparent !important;
}

.datasheet-grid-title-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

/* DatePicker */
.dsg-cell .eq-picker-input {
  position: absolute;
  inset: 0;
  padding: 0 10px;
}

/* Drawer */
.eq-drawer-right.eq-drawer-open .eq-drawer-content-wrapper {
  max-width: 80%;
  /* Use of MAX_WINDOW_SIZE_FOR_MOBILE */
    @media screen and (max-width: 575px) {
      max-width: 100%;
    }
}

/* Form */
.eq-row.eq-form-item:last-child {
  margin-bottom: 0;
}

.eq-form-item-label {
  font-weight: 600;
}

.eq-form-item-control-input {
  align-items: start;
}

/* Input */
.text-align-right-input,
.text-align-right-input input {
  text-align: right;
}

.dsg-cell .eq-input-suffix {
  margin-left: 8px;
  color: #8f8f8f;
}

.soft-disabled-input input[disabled],
.soft-disabled-input[disabled] {
  color: rgba(26, 54, 64, 0.85);
}

/* Menu */
.eq-menu-dark .eq-menu-item-divider {
  background-color: rgba(255, 255, 255, 0.15);
}

.eq-menu-item-selected,
.eq-menu-submenu-selected.eq-menu-submenu-inline:not(.eq-menu-submenu-open),
.eq-menu-submenu-selected:not(.eq-menu-submenu-inline) {
  box-shadow: inset 2px 0 0 0 #fff;
}

.eq-menu-submenu-selected.eq-menu-submenu-inline:not(.eq-menu-submenu-open),
.eq-menu-submenu-selected:not(.eq-menu-submenu-inline) {
  background-color: rgba(255, 255, 255, 0.1);
}

.eq-menu-dark:not(.eq-menu-inline) .eq-menu-submenu-open.eq-menu-submenu-active {
  color: white;
}

/* PageHeader */
.eq-page-header-heading-left,
.eq-page-header-heading-left .eq-avatar {
  overflow: inherit;
}

.eq-page-header-custom .eq-page-header-heading-extra {
  white-space: normal;
  margin: 2px 0;
}

.eq-page-header-custom .eq-page-header-heading-extra>* {
  margin: 2px;
}

/* Pagination */
.eq-pagination-jump-next.eq-pagination-jump-next-custom-icon+.eq-pagination-item {
  display: none;
}

.eq-table-pagination.eq-pagination {
  margin: 20px;
}

/* Select */
.eq-select.eq-select-multiple .eq-select-selector>span:not(.eq-select-selection-placeholder) {
  margin-top: 2px;
  margin-bottom: 2px;
}

.eq-select.eq-select-multiple .eq-select-selector>span>.eq-tag {
  white-space: break-spaces;
}

/* Skeleton */
.eq-skeleton>span {
  vertical-align: middle !important;
}

/* Table */
.cap-table-column {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.eq-table-thead>tr>th {
  padding: 8px 16px;
}

.eq-table tfoot>tr>th,
.eq-table tfoot>tr>td {
  padding: 8px 16px;
  background: #fafafa;
  font-weight: 500;
}

.eq-table tfoot>tr>th,
.eq-table tfoot>tr>td.body-in-summary-row {
  padding: 0;
  background: transparent;
  font-weight: unset;
  border-bottom: unset;
}

.eq-pro-table-column-setting-overlay .eq-popover-inner-content {
  width: 350px;
}

.eq-pro-table-column-setting-list-item-title {
  max-width: 230px;
}

.eq-table-cell:not(.wrap-column),
th.eq-table-cell {
  white-space: nowrap;
  /* Use of MAX_WINDOW_SIZE_FOR_MOBILE */
    @media screen and (max-width: 575px) {
      white-space: unset;
    }
}

.row-classname-color-light-grey * {
  color: lightgrey !important;
}

.row-classname-color-light-grey .eq-tag-has-color {
  background-color: grey !important;
}

.row-classname-cursor-pointer {
  cursor: pointer;
}

.row-classname-cursor-grab {
  cursor: grab;
}

.row-classname-loading {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: eq-skeleton-loading 1.4s ease infinite;
}

.row-classname-loading>* {
  background-color: transparent !important;
  color: transparent !important;
}

.eq-table-tbody>tr.eq-table-row.row-classname-loading:hover>td,
.eq-table-tbody>tr.eq-table-row.row-classname-selected:hover>td {
  background-color: transparent;
}

.row-classname-selected {
  background-color: #4580e6;
  color: white;
}

tr.eq-table-expanded-row>td {
  padding: 0 0 0 57px;
}

/* Tree */
.eq-tree>* .eq-tree-treenode,
.eq-tree .eq-tree-node-content-wrapper,
.eq-tree-title {
  width: 100%;
}

.eq-tree-show-line .eq-tree-switcher {
  background: transparent;
}

.tree-hide-switcher .eq-tree-switcher {
  display: none;
}

.eq-tree .eq-tree-node-content-wrapper {
  display: flex;
  align-items: center;
}

/* Upload */
.eq-upload.eq-upload-select {
  display: block;
}

/* CKEditor */
.ck.ck-editor__editable_inline {
  min-height: 250px;
  border: 1px solid #e6e6e6 !important;
  padding: 0 var(--ck-spacing-standard);
}